<template>
  <div class="admin">
    <div class="sub-wrap">
      <div class="container">
        <header class="admin-header">
          <h1>Displays</h1>
        </header>
        <admin-nav></admin-nav>
        <div class="admin-body">
          <div v-if="loading">
            <div class="loading-wrap">
              <div class="loading-spinner"></div>
            </div>
          </div>
          <div v-else>
            <div class="form-wrap" style="margin-bottom: 20px">
              <input
                v-model="search_phrase"
                v-on:input="search_handler"
                class="form-input"
                type="text"
                name="search_phrase"
                id="search_phrase"
                placeholder="Suchbegriff eingeben..."
              />
            </div>

            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th>Medium</th>
                  <th>{{ $t("global.name") }}</th>
                  <th>Auth Code</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="device in devices" v-bind:key="device.uuid">
                  <td width="5%">
                    <span v-if="device.status == 'created'" class="status status-yellow"></span>
                    <span v-if="device.status == 'linked'" class="status status-yellow"></span>
                    <span v-if="device.status == 'located'" class="status status-yellow"></span>
                    <span v-if="device.status == 'running'" class="status status-green"></span>
                    <span v-if="device.status == 'deleted'" class="status status-red"></span>
                  </td>
                  <td width="10%">
                    <img :src="device.device_image" style="max-height: 82px; display: block" />
                  </td>
                  <td width="25%">{{ device.name }}</td>
                  <td width="15%">{{ device.auth_code }}</td>
                  <td width="10%" style="text-align: right">
                    <router-link :to="'/admin/displays/' + device.uuid" class="campaign-wrap">
                      <span class="material-icons" style="color: #444">create</span>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="row row-gutter-20" style="margin-top: 20px">
              <div class="col-12">
                <a v-if="pagination.links.prev" @click="prev_page" class="button button-ci button-100 button-round button-small">Vorherige Seite</a>
                <a v-else>-</a>
              </div>
              <div class="col-12">
                <a v-if="pagination.links.next" @click="next_page" class="button button-ci button-100 button-round button-small">Nächste Seite</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>

<script>
import axios from "axios";
import AdminNav from "@/components/admin_nav";

export default {
  name: "dashboard",
  components: {
    AdminNav,
  },
  data() {
    return {
      loading: true,
      search_phrase: "",
      devices: [],
      pagination: {},
    };
  },
  methods: {
    get_data() {
      this.get_devices(1);
    },
    get_devices(page) {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/devices?page=" + page, {
          headers: { Authorization: this.$store.getters.getToken },
        })
        .then((response) => {
          this.devices = response.data.devices;
          this.pagination = response.data.meta.pagination;
          this.loading = false;
        });
    },
    search_handler() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/admin/devices?search_phrase=" + this.search_phrase, { headers: { Authorization: this.$store.getters.getToken } })
        .then((response) => {
          this.devices = response.data.devices;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    next_page() {
      this.loading = true;
      this.get_devices(this.pagination.links.next.split("=")[1]);
    },
    prev_page() {
      this.loading = true;
      this.get_devices(this.pagination.links.prev.split("=")[1]);
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
